/*!
 * This Source Code Form is subject to the terms of the Mozilla Public License,
 * v. 2.0. If a copy of the MPL was not distributed with this file, You can
 * obtain one at http://mozilla.org/MPL/2.0/. OpenMRS is also distributed under
 * the terms of the Healthcare Disclaimer located at http://openmrs.org/license.
 *
 * Copyright (C) OpenMRS Inc. OpenMRS is a registered trademark and the OpenMRS
 * graphic logo is a trademark of OpenMRS Inc.
 */

$theme-colors: (
  "primary": #337ab7,
);

$link-color: #275c8a;

@import "~bootstrap/scss/bootstrap.scss";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,400,300,700);

html {
  font-size: 14px;
}

.logo1 {
  float: left;

  height: 2.785rem;
  width: 20.2857rem;
}

.logotext {
  margin-top: 2.15rem;
}

.vertical-align-center {
  display: flex;
  align-items: center;
}

.ma {
  margin-left: 1.5rem;
  font-size: 2.5rem;
}

.f {
  margin: 10px 0 10px 30px;
}

.buttonfix {
  top: 30px;
  float: right;
}

.maintable {
  margin-bottom: 0;
}

.title-type {
  margin-right: 5%;
  margin-bottom: 5px;
}

.title-name {
  margin-bottom: 5px;
}

.push-right {
  margin-left: 20px;
}

.textdec {
  text-align: center;
  padding-top: 3%;
}

body {
  color: #363463;
  font-family: "Open Sans", sans-serif;
}

header {
  height: 6.214rem;
}

.p {
  margin-left: 2%;
  margin-top: 3%;
}

.table {
  color: #363463;
}

tr {
  line-height: 25px;
  min-height: 25px;
  height: 25px;
}

.descmod {
  padding-top: 2%;
}

a:hover {
  cursor: pointer;
}

footer {
  font-size: 0.8rem;
  color: #555555;
  text-align: center;
  margin-top: 3rem;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

.badge {
  padding: 0.2rem 0.6rem 0.3rem;
}

.list-group-item {
  padding-bottom: 0;
  padding-top: 0;
}

.left-margin {
  padding-left: 0;
  margin-bottom: 2%;
  margin-top: 1%;
}

.input-group {
  margin-bottom: 2%;
}

.table td {
  width: auto !important;
}

.highlight {
  font-weight: bold;
  background-color: yellow;
}

.addon {
  border-top: 1px lightgray solid;
  padding-top: 10px;
}

.maintainer {
  padding-right: 1rem;
}

.hidden {
  display: none;
}
